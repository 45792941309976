<template>
  <section class="MyTeam-View">
    <router-view/>
  </section>
</template>

<script>

export default {name: "MyTeam-View"}
</script>
